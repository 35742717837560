import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import AITraining from "./pages/admin/ai_trainings/id";
import AITrainings from "./pages/admin/ai_trainings";
import AITrainingDocumentation from "./pages/documentation/ai_training_documentation";
import Analytics from "./pages/admin/analytics";
import AnalyticsDocumentation from "./pages/documentation/analytics_documentation";
import APIKeysDocumentation from "./pages/documentation/api_keys_documentation";
import APIKeys from "./pages/admin/api_keys";
import APIKey from "./pages/admin/api_keys/id";
import Chats from "./pages/admin/chats";
import Chat from "./pages/admin/chats/id";
import ChatsDocumentation from "./pages/documentation/chats_documentation";
import Configurations from "./pages/admin/configurations";
import Contact from "./pages/contact";
import CreateAPIKey from "./pages/admin/api_keys/create";
import Dashboard from "./pages/admin/dashboard";
import Documentation from "./pages/documentation/index";
import Login from "./pages/login";
import SignUp from "./pages/sign_up";
import Invoices from "./pages/admin/invoices";
import InvoicesEmailFail from "./pages/admin/invoices/email-fail";
import Invoice from "./pages/admin/invoices/id";
import Profile from "./pages/admin/profile";
import ProfileDocumentation from "./pages/documentation/profile_documentation";
import NewAITraining from "./pages/admin/ai_trainings/new";
import EditAITraining from "./pages/admin/ai_trainings/edit";
import EditApiKey from "./pages/admin/api_keys/edit";
import VFDConfiguration from "./pages/admin/configurations/vfd_configuration";
import XeroCallback from "./pages/xero-callback";
import ZReports from "./pages/admin/zreports";
import ZReport from "./pages/admin/zreports/id";
import SupportEngine from "./SupportEngine";
import "./App.css";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/about" element={<About />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route
          path="/analytics-documentation"
          element={<AnalyticsDocumentation />}
        />
        <Route path="/chats" element={<Chats />} />
        <Route path="/chats/:id" element={<Chat />} />
        <Route path="/chats-documentation" element={<ChatsDocumentation />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/ai-trainings" element={<AITrainings />} />
        <Route path="/ai-trainings/:id" element={<AITraining />} />
        <Route path="/ai-trainings/new" element={<NewAITraining />} />
        <Route path="/ai-trainings/edit/:id" element={<EditAITraining />} />
        <Route path="/api-keys/edit/:id" element={<EditApiKey />} />

        <Route
          path="/ai-trainings-documentation"
          element={<AITrainingDocumentation />}
        />
        <Route
          path="/api-keys-documentation"
          element={<APIKeysDocumentation />}
        />
        <Route path="/api-keys" element={<APIKeys />} />
        <Route path="/api-keys/:id" element={<APIKey />} />
        <Route path="/api-keys/create" element={<CreateAPIKey />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/invoices-email-fail" element={<InvoicesEmailFail />} />
        <Route path="/invoices/:id" element={<Invoice />} />
        <Route path="/profile" element={<Profile />} />
        <Route
          path="/profile-documentation"
          element={<ProfileDocumentation />}
        />
        <Route path="/configurations" element={<Configurations />} />
        <Route path="/vfd-configuration" element={<VFDConfiguration />} />
        <Route path="/xero-callback" element={<XeroCallback />} />
        <Route path="/zreports" element={<ZReports />} />
        <Route path="/zreports/:id" element={<ZReport />} />
      </Routes>

      <SupportEngine />
    </>
  );
};

export default App;
