import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const EditAITraining = () => {
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  let { id } = useParams();

  const [title, setTitle] = useState("");
  const [trainingData, setTrainingData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState("");

  const getUser = async () => {
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("struts_ai_access_token");
    var userID = localStorage.getItem("struts_ai_user_id");

    try {
      const res = await axios({
        url: apiBaseURL + "/users/" + userID,
        headers: {
          "x-strutsai-token": accessToken,
          "x-strutsai-application": "user",
        },
        method: "GET",
      });
      console.log(res);
    } catch (err) {
      console.error("getUser err = " + err.message);
    }
  };

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_ai_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    var url = apiBaseURL + "/ai-trainings/" + id;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let aiTrainingData = JSON.parse(
          JSON.stringify(response.data.ai_training)
        );
        setTitle(aiTrainingData.title);
        setTrainingData(aiTrainingData.training_data);
      })
      .catch((err) => console.error("fetch AI Training err = " + err.message));

    getUser();

    window.scrollTo(0, 0);
  }, [apiBaseURL, id, navigate]);

  const updateAITraining = (event) => {
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("struts_ai_access_token");

    setIsLoading(true);
    axios({
      url: `${apiBaseURL}/ai-trainings/` + id,
      method: "PUT",
      withCredentials: false,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
        "x-strutsai-application": "user",
        "x-client-identifier": "web",
      },
      data: {
        chatbot_id: 0,
        title: title,
        training_data: trainingData,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data.ai_training.id) {
          var successMessage = "AI Training updated successfully.";
          setSuccessAlert(successMessage);
          setErrorAlert("");
        } else {
          setErrorAlert("Failed to Update AI Training.");
          setSuccessAlert("");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorAlert("Failed to Update AI Training. " + error);
        setSuccessAlert("");
      });
  };

  let alert;
  if (errorAlert.length > 0) {
    alert = (
      <div className="notification is-danger">&nbsp;&nbsp;{errorAlert} </div>
    );
  } else if (successAlert.length > 0) {
    alert = (
      <div className="notification is-success">&nbsp;&nbsp;{successAlert} </div>
    );
  }

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Edit AI Training</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-50px" }}
            >
              <nav
                class="breadcrumb has-arrow-separator"
                aria-label="breadcrumbs"
                style={{ fontSize: "9pt" }}
              >
                <ul>
                  <li>
                    <Link
                      to="/dashboard"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ai-trainings"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      AI Training
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/ai-trainings/edit/" + id}
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Edit AI Training
                    </Link>
                  </li>
                </ul>
              </nav>

              {alert}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Title"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <textarea
                      class="textarea"
                      placeholder="AI Training Data"
                      name="training-data"
                      value={trainingData}
                      onChange={(e) => setTrainingData(e.target.value)}
                      rows="20"
                    ></textarea>
                  </div>

                  <br />

                  {!isLoading && (
                    <div className="field">
                      <button
                        className="button is-link"
                        onClick={updateAITraining}
                      >
                        Update
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditAITraining;
