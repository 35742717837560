import React from "react";
import { Link } from "react-router-dom";

const DocumentationSideMenu = () => {
  return (
    <>
      <aside className="menu" style={{ padding: "10px" }}>
        <p className="menu-label">Getting Started</p>
        <ul className="menu-list">
          <li>
            <Link to="/documentation">Getting Started</Link>
          </li>
        </ul>
        <p className="menu-label">AI Training</p>
        <ul className="menu-list">
          <li>
            <Link to="/ai-trainings-documentation">AI Training</Link>
          </li>
        </ul>
        <p className="menu-label">Chats</p>
        <ul className="menu-list">
          <li>
            <Link to="/chats-documentation">Chats</Link>
          </li>
        </ul>
        <p className="menu-label">Analytics</p>
        <ul className="menu-list">
          <li>
            <Link to="/analytics-documentation">Chat Analytics</Link>
          </li>
          {/* <li>
            <Link to="/system-logs-documentation">System Logs</Link>
          </li> */}
        </ul>
        <p className="menu-label">Settings</p>
        <ul className="menu-list">
          <li>
            <Link to="/api-keys-documentation">API Keys</Link>
          </li>
          <li>
            <Link to="/profile-documentation">Profile</Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default DocumentationSideMenu;
