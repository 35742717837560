import React, { useEffect, useRef, useState } from "react";
import AdminNavbar from "../../components/admin_navbar";
import AdminSideMenu from "../../components/admin_side_menu";
import BarChart from "../../components/bar_chart";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import ContentHeader from "./shared/ContentHeader";
import DashboardAnalytics from "./shared/DashboardAnalytics";

const Dashboard = () => {
  const navigate = useNavigate();

  let contentHeader = useRef();
  let dashboardAnalytics = useRef();

  const [chats, setRecentChats] = useState([]);
  const [chartData, setChartData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_ai_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    contentHeader.current = ContentHeader(
      "Dashboard",
      "/documentation",
      "Dashboard analytics"
    );

    const getDashboardAnalytics = async () => {
      setIsLoading(true);
      var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
      var accessToken = localStorage.getItem("struts_ai_access_token");

      try {
        const response = await axios({
          url: apiBaseURL + "/analytics/dashboard",
          headers: {
            Authorization: "Bearer " + accessToken,
            "x-strutsai-application": "user",
          },
          method: "GET",
        });

        if (response.status === 401 || response.status === 403) {
          navigate("/");
        }

        let analyticsData = JSON.parse(JSON.stringify(response.data));
        setRecentChats(analyticsData.recent_chats);

        dashboardAnalytics.current = DashboardAnalytics(
          analyticsData.chats_count,
          analyticsData.ai_trainings_count,
          analyticsData.api_keys_count,
          analyticsData.chatbots_count
        );

        let chartSummariesData = analyticsData.chart_summary;

        setChartData({
          labels: chartSummariesData.map((data) => data.month),
          datasets: [
            {
              label: "Chats",
              data: chartSummariesData.map((data) => data.total_chats),
            },
          ],
        });

        setIsLoading(false);
      } catch (err) {
        console.error("getDashboardAnalytics err = " + err);
      }
    };

    getDashboardAnalytics();
  }, [navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title" style={{ color: "#404040" }}>
            Dashboard
          </h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-50px" }}
            >
              {contentHeader.current}

              {dashboardAnalytics.current}

              <br />
              <hr
                style={{
                  height: "1px",
                  backgroundColor: " #ccc",
                  border: "none",
                }}
              />
              <br />
              <div className="tile is-ancestor">
                {/* <PieChart chartData={chartData} /> */}

                {/* <Line
                  data={barChartConfig.data}
                  options={barChartConfig.options}
                />
                <Pie
                  data={barChartConfig.data}
                  options={barChartConfig.options}
                /> */}

                {/* {chartRender} */}

                {/* <Bar data={chartData} /> */}

                {chartData && (
                  <BarChart
                    labels={chartData.labels}
                    datasets={chartData.datasets}
                  />
                )}
              </div>

              <br />
              <br />
              <p style={{ marginTop: "10px" }}>
                <b>Recent Conversations</b>
              </p>
              <hr
                style={{
                  height: "1px",
                  backgroundColor: " #ccc",
                  border: "none",
                }}
              />

              <table className="table is-striped is-hoverable">
                <thead style={{ backgroundColor: "#00d1b2" }}>
                  <tr>
                    <th width="45%">Input Text</th>
                    <th width="45%">Response</th>
                    <th width="5%">Tokens</th>
                    <th width="5%">View</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    chats.map((chat) => (
                      <tr key={chat.id}>
                        <th>{chat.input_text}</th>
                        <td>{chat.response}</td>
                        <td>{chat.llm_token_usage}</td>
                        <th>
                          <Link to={{ pathname: `/chats/${chat.id}` }}>
                            <span
                              class="icon has-text-primary"
                              data-tooltip="View"
                            >
                              <i
                                class="fas fa-eye is-primary"
                                data-tooltip="View"
                              ></i>
                            </span>
                          </Link>
                        </th>
                      </tr>
                    ))}
                </tbody>
              </table>
              <br />
              <br />
              {/* {{.bar}} */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
