import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "bulma-pagination-react";
import ChatPage from "../../../components/chat_page";

const Chats = () => {
  const navigate = useNavigate();
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [chats, setChats] = useState([]);
  const [per, setPer] = useState(8);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_ai_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
    setPer(8);

    var url = apiBaseURL + "/chats?per=" + per;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        if (response.status === 401 || response.status === 403) {
          navigate("/");
        }
        let chatsData = JSON.parse(JSON.stringify(response.data));
        let chatsCount = chatsData.pagination.count;
        setCount(chatsCount);
        setChats(chatsData.chats);

        let numPages = Math.ceil(chatsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("fetchChats err = " + err.message));
  }, [apiBaseURL, per, navigate]);

  const searchChats = (searchTermText) => {
    var accessToken = localStorage.getItem("struts_ai_access_token");
    var url = apiBaseURL + "/chats?search=" + searchTermText;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let chatsData = JSON.parse(JSON.stringify(response.data));
        let chatsCount = chatsData.pagination.count;
        setCount(chatsCount);
        setChats(chatsData.chats);

        let numPages = Math.ceil(chatsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("searchChats err = " + err.message));
  };

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage);
    filterChats(selectedPage);
  };

  const filterChats = (selectedPage) => {
    var accessToken = localStorage.getItem("struts_ai_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url = apiBaseURL + "/chats?page=" + selectedPage + "&per=" + per;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let chatsData = JSON.parse(JSON.stringify(response.data));
        let chatsCount = chatsData.pagination.count;
        setCount(chatsCount);
        setChats(chatsData.chats);

        let numPages = Math.ceil(chatsCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("filter Chats err = " + err.message));
  };

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title" style={{ color: "#404040" }}>
            Conversations
          </h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <div style={{ marginBottom: "12px", marginTop: "-50px" }}>
                <nav
                  className="breadcrumb has-arrow-separator"
                  aria-label="breadcrumbs"
                  style={{ fontSize: "9pt" }}
                >
                  <ul>
                    <li>
                      <Link
                        to="/dashboard"
                        style={{
                          color: "#00d1b2",
                          fontWeight: "bold",
                          fontSize: "9pt",
                        }}
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/chats"
                        style={{
                          color: "#00d1b2",
                          fontWeight: "bold",
                          fontSize: "9pt",
                        }}
                      >
                        Conversations
                      </Link>
                    </li>
                  </ul>
                </nav>
                <b>Conversations ({count})</b>
                &nbsp;&nbsp;
                <Link
                  to="/chats-documentation"
                  style={{
                    color: "#00d1b2",
                    fontWeight: "bold",
                    fontSize: "9pt",
                  }}
                >
                  Info
                </Link>
              </div>

              {/* <p>
                <input
                  className="input"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => searchChats(e.target.value)}
                />
              </p> */}
              <hr
                style={{
                  height: "1px",
                  backgroundColor: " #ccc",
                  border: "none",
                }}
              />

              <ChatPage chats={chats} searchFunction={searchChats} />
              <br />

              <div
                className="container"
                style={{
                  margin: "0 auto",
                  width: "max-content",
                }}
              >
                <nav
                  className="pagination is-centered"
                  role="navigation"
                  aria-label="pagination"
                >
                  <Pagination
                    pages={pageCount}
                    currentPage={currentPage}
                    onChange={(page) => handlePageClick(page)}
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Chats;
