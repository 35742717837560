import React from "react";
import { Link } from "react-router-dom";

const ContentHeader = (page, documentationURL, description) => {
  return (
    <>
      <header class="is-clearfix">
        <div class="cats is-pulled-right has-text-right">
          <small>
            Struts AI
            <br />
            {page}
            <br />{" "}
            {/* <span class="has-text-weight-bold" style={{ color: "#808080" }}>
              v.0.1
            </span> */}
          </small>
        </div>
        <div>
          <div>
            <b style={{ color: "#282828" }}>{page}</b>
            &nbsp;&nbsp;
            <Link
              to={documentationURL}
              style={{
                color: "#00d1b2",
                fontWeight: "bold",
                fontSize: "9pt",
              }}
            >
              Info
            </Link>
          </div>
          <small>{description}</small>
        </div>
        <hr
          style={{
            height: "1px",
            backgroundColor: " #ccc",
            border: "none",
          }}
        />
        {/* <hr></hr> */}
      </header>
    </>
  );
};

export default ContentHeader;
