import React from "react";
import { Link } from "react-router-dom";

const AdminSideMenu = () => {
  return (
    <>
      <aside className="menu" style={{ padding: "10px" }}>
        <p className="menu-label">Dashboard</p>
        <ul className="menu-list">
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
        </ul>
        <p className="menu-label">AI Trainings</p>
        <ul className="menu-list">
          <li>
            <Link to="/ai-trainings">AI Trainings</Link>
          </li>
          {/* <li>
            <Link to="/invoices-email-fail">Email Fail</Link>
          </li> */}
        </ul>
        <p className="menu-label">Conversations</p>
        <ul className="menu-list">
          <li>
            <Link to="/chats">Conversations</Link>
          </li>
          {/* <li>
            <Link to="/invoices-email-fail">Email Fail</Link>
          </li> */}
        </ul>
        <p className="menu-label">Analytics</p>
        <ul className="menu-list">
          <li>
            <Link to="/analytics">Chat Analytics</Link>
          </li>
        </ul>
        <p className="menu-label">Settings</p>
        <ul className="menu-list">
          {/* <li>
            <Link to="/configurations">Configurations</Link>
          </li> */}
          <li>
            <Link to="/api-keys">API Keys</Link>
          </li>
          <li>
            <Link to="/profile">Profile</Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default AdminSideMenu;
