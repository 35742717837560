import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      class=""
      style={{
        position: "relative",
        padding: "3rem 1.5rem 3rem",
        bottom: "-15px",
        // left: "35%",
        overflow: "hidden",
        backgroundColor: "#F3F2F0",
        minHeight: "10vh",
      }}
    >
      <div class="content has-text-centered">
        <p>
          Powered by{" "}
          <strong>
            <a
              href="https://www.strutstechnology.co.ke"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00d1b2" }}
            >
              Struts Technology
            </a>
          </strong>
          &nbsp;&nbsp;{" "}
          <Link to="/" style={{ color: "#00d1b2" }}>
            Home
          </Link>{" "}
          |{" "}
          <Link to="/about" style={{ color: "#00d1b2" }}>
            About
          </Link>{" "}
          |{" "}
          <Link to="/signup" style={{ color: "#00d1b2" }}>
            SignUp
          </Link>{" "}
          |{" "}
          <Link to="/login" style={{ color: "#00d1b2" }}>
            SignIn
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
