import React from "react";
import { Link } from "react-router-dom";

const DashboardAnalytics = (
  chatsCount,
  aiTrainingsCount,
  apiKeysCount,
  chatbotsCount
) => {
  return (
    <>
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <article class="tile is-child box transparent">
            <Link to="/chats">
              <p class="title">{chatsCount}</p>
              <p class="subtitle">Conversations</p>
            </Link>
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box">
            <Link to="/ai-trainings">
              <p class="title has-text-info">{aiTrainingsCount}</p>
              <p class="subtitle">AI Trainings</p>
            </Link>
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box">
            <Link to="/api-keys">
              <p class="title has-text-primary">{apiKeysCount}</p>
              <p class="subtitle">API Keys</p>
            </Link>
          </article>
        </div>

        <div class="tile is-parent">
          <article class="tile is-child box accent">
            <Link to="/chats">
              <p class="title has-text-warning">{chatbotsCount}</p>
              <p class="subtitle">Chatbots Count</p>
            </Link>
          </article>
        </div>
      </div>
    </>
  );
};

export default DashboardAnalytics;
