import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const AdminNavabar = () => {
  const navigate = useNavigate();

  const logoutUser = (event) => {
    localStorage.removeItem("struts_ai_access_token");
    localStorage.removeItem("struts_ai_user_id");
    navigate("/");
  };

  return (
    <nav
      className="navbar is-light"
      role="navigation"
      aria-label="main navigation"
      style={{ backgroundColor: "#F3F2F0" }}
    >
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/dashboard">
            <img
              src="/assets/logo.png"
              alt="Struts AI"
              style={{ maxHeight: "2.50rem" }}
            />
            &nbsp; <b>Struts AI</b>
          </Link>

          <Link
            to="/dashboard"
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </Link>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <Link to="/dashboard" className="navbar-item">
              Dashboard
            </Link>

            <Link to="/documentation" className="navbar-item">
              Documentation
            </Link>
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <button className="button is-primary" onClick={logoutUser}>
                  <strong>Log Out</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AdminNavabar;
