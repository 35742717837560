import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WebNavbar from "../components/web_navbar";
import Footer from "../components/footer";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import config from "../configs/chatbotConfig";
import AIMessageParser from "../chatbot/AIMessageParser";
import AIActionProvider from "../chatbot/AIActionProvider.js";
import { createClientMessage } from "react-chatbot-kit";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_ai_access_token");
    if (accessToken) {
      navigate("/dashboard");
    }

    window.scrollTo(0, 0);
    createClientMessage("Hello there.");
  }, [navigate]);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <h1 className="title">Struts AI</h1>
          <p className="subtitle">
            Unleashing the Power of AI to Revolutionize Your Business
          </p>

          <br />
          <br />

          <div class="columns">
            <div class="column is-three-fifths">
              <br />
              <h3 class="title is-4">
                Elevate Your Business with Automated Chat Solutions!{" "}
              </h3>
              <b>Unlock the Power of Chatbots:</b> Train Your Personal Assistant
              to Engage Customers, Gather Information, and Follow Up Seamlessly.
              <br /> <br />
              <b>Say Hello to Effortless Customer Support:</b> Harness the
              Potential of AI-Powered Chatbots to Handle Queries and Boost Your
              Sales!
              <br /> <br />
              <b>Customize Your Conversations:</b> Craft Tailored Interactions
              and Capture Customer Details for Future Success. Don't Miss Out on
              Opportunities - Automate and Prosper! <br />
              <br />
              Get started now.
              <br />
              <br />
              <div>
                <div className="buttons">
                  <Link to="/signup" className="button" href="/signup">
                    <strong>Sign Up</strong>
                  </Link>
                  &nbsp;
                  <Link to="/login" className="button is-primary" href="/login">
                    <strong>Sign in</strong>
                  </Link>
                </div>
              </div>
            </div>
            <div class="column is-1">&nbsp;</div>
            <div class="column">
              <div
                style={{
                  border: "2px solid gray",
                  borderRadius: "7.5px",
                  width: "max-content",
                }}
              >
                <Chatbot
                  config={config}
                  messageParser={AIMessageParser}
                  actionProvider={AIActionProvider}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
