import React, { useState } from "react";

const ChatPage = ({ chats, searchFunction }) => {
  const [selectedChat, setSelectedChat] = useState({});

  const toggleModal = (props, id) => {
    let selectedAPIKey = chats.filter((item) => {
      return item.id === id;
    });

    setSelectedChat(selectedAPIKey[0]);
  };

  return (
    <>
      <div className="columns is-mobile" style={{ height: "max-content" }}>
        <div className="column is-paddingless is-5-desktop is-6-tablet is-4-mobile sidebar-user is-fullheight">
          <div className="top-field is-hidden-mobile has-text-centered">
            <form>
              <div className="field">
                <p className="control has-icons-right">
                  <input
                    className="input"
                    type="search"
                    placeholder="Search..."
                    onChange={(e) => searchFunction(e.target.value)}
                  />
                  <span className="icon is-small is-right">
                    <svg
                      className="bi bi-search"
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                        clip-rule="evenodd"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </p>
              </div>
            </form>
          </div>
          <div className="list">
            {/* <a className="list-item active box">
                      <div className="media">
                        <div className="media-left">
                          <figure className="image is-48x48 is-32x32-mobile">
                            <img
                              src="https://via.placeholder.com/150"
                              alt="Profile pic"
                              className="is-rounded"
                            />
                          </figure>
                        </div>
                        <div className="media-content is-hidden-mobile">
                          <div className="content">
                            <p>
                              <strong>User's Name</strong>
                              <br />
                              <small>No unread messages</small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a> */}
            {/* <a className="list-item box">
                      <div className="media">
                        <div className="media-left">
                          <figure className="image is-48x48 is-32x32-mobile">
                            <img
                              src="https://via.placeholder.com/150"
                              alt="Profile pic"
                              className="is-rounded"
                            />
                          </figure>
                        </div>
                        <div className="media-content is-hidden-mobile">
                          <div className="content">
                            <p>
                              <strong>User's Name</strong>
                              <br />
                              <small className="has-text-weight-semibold">
                                1 new message
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a> */}

            {/* Dynamic chats */}
            {chats.map((chat) => (
              <span
                className="list-item box"
                onClick={(event) => toggleModal(event, chat.id)}
              >
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48 is-32x32-mobile">
                      <img
                        src="https://via.placeholder.com/150"
                        alt="Profile pic"
                        className="is-rounded"
                      />
                    </figure>
                  </div>
                  <div className="media-content is-hidden-mobile">
                    <div className="content">
                      <p>
                        <strong>{chat.input_text}</strong>
                        <br />

                        <small className="has-text-weight-semibold">
                          {chat.llm_token_usage} tokens
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </span>
            ))}
          </div>
        </div>

        <div className="column is-7-desktop is-6-tablet is-8-mobile is-paddingless">
          <nav className="navbar has-shadow user-nav">
            <div className="navbar-start">
              <div className="media">
                <div className="media-left">
                  <figure className="image is-48x48">
                    <img
                      src="https://via.placeholder.com/150"
                      alt="Profile pic"
                      className="is-rounded"
                    />
                  </figure>
                </div>
                <div className="media-content">
                  <div className="content">
                    <p>
                      <strong>User's Name</strong>
                      <br />
                      <small>online</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-end">
              <span className="navbar-item">
                <span className="icon is-size-2">
                  <svg
                    className="bi bi-gear-fill"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 01-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 01.872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 012.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 012.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 01.872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 01-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 01-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 100-5.86 2.929 2.929 0 000 5.858z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </span>
              <span className="navbar-item">
                <span className="icon is-size-2">
                  <svg
                    className="bi bi-info-circle-fill"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 16A8 8 0 108 0a8 8 0 000 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </span>
            </div>
          </nav>

          <div className="columns">
            <div className="column is-8 is-12-mobile is-flex hero is-fullheight message-window">
              <div
                className="flex-item-1"
                style={{
                  marginLeft: "20px",
                  paddingLeft: "15px",
                  marginTop: "10px",
                }}
              >
                <br />
                <strong>{selectedChat.input_text}</strong>
                <br />
                <br />
                {selectedChat.response}
              </div>
              {/* <div className="flex-item-2">
                        <form>
                          <div className="field has-addons">
                            <div className="control">
                              <input
                                className="input"
                                type="text"
                                placeholder="Type your message..."
                              />
                            </div>
                            <div className="control">
                              <button type="submit" className="button">
                                <svg
                                  height="36px"
                                  width="36px"
                                  viewBox="0 0 36 36"
                                >
                                  <g fill="none" fill-rule="evenodd">
                                    <g>
                                      <polygon points="0 36 36 36 36 0 0 0"></polygon>
                                      <path
                                        d="M31.1059281,19.4468693 L10.3449666,29.8224462 C8.94594087,30.5217547 7.49043432,29.0215929 8.17420251,27.6529892 C8.17420251,27.6529892 10.7473302,22.456697 11.4550902,21.0955966 C12.1628503,19.7344961 12.9730756,19.4988922 20.4970248,18.5264632 C20.7754304,18.4904474 21.0033531,18.2803547 21.0033531,17.9997309 C21.0033531,17.7196073 20.7754304,17.5095146 20.4970248,17.4734988 C12.9730756,16.5010698 12.1628503,16.2654659 11.4550902,14.9043654 C10.7473302,13.5437652 8.17420251,8.34697281 8.17420251,8.34697281 C7.49043432,6.9788693 8.94594087,5.47820732 10.3449666,6.1775158 L31.1059281,16.553593 C32.298024,17.1488555 32.298024,18.8511065 31.1059281,19.4468693"
                                        fill="#363636"
                                      ></path>
                                    </g>
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div> */}
            </div>
            {/* <div className="column is-hidden-mobile sidebar-profile">
                      Profile Section
                    </div> */}
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default ChatPage;
