import React from "react";

const Modal = ({
  closeModal,
  modalState,
  deleteFunction,
  recordName,
  recordId,
}) => {
  if (!modalState) {
    return null;
  }

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal} />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Confirm Deletion</p>
          <button className="delete" onClick={closeModal} />
        </header>
        <section className="modal-card-body">
          <div className="content">
            <p>Are you sure you want to delete {recordName}?</p>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-danger" onClick={deleteFunction}>
            Delete
          </button>
          <button className="button" onClick={closeModal}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Modal;
