import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";

const ChatsDocumentation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">Chats</h2>

              <p className="">
                In the{" "}
                <Link to="/chats" style={{ color: "#00d1b2" }}>
                  chats
                </Link>{" "}
                section, you can be able to view interactions with the AI that
                you have setup. This can give you insights on how your users are
                interacting with the system, and it can also guide you in how to
                better train the AI.
              </p>

              <br />
              <br />
              <img src="/assets/chats.png" alt="Chats" />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChatsDocumentation;
