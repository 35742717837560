import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const EditAPIKey = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [per, setPer] = useState(20);
  const [aiTrainings, setAITrainings] = useState([]);
  const [keyName, setKeyName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAITrainingID, setSelectedAITrainingID] = useState(0);
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState("");

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_ai_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    var apiKeyURL = apiBaseURL + "/api_keys/" + id;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(apiKeyURL, config)
      .then((response) => {
        let apiKeyDataData = JSON.parse(JSON.stringify(response.data.api_key));
        setSelectedAITrainingID(apiKeyDataData.ai_training_id);
        setKeyName(apiKeyDataData.name);
      })
      .catch((err) => console.error("fetchInvoices err = " + err.message));

    setPer(20);

    var aiTrainingsURL = apiBaseURL + "/ai-trainings?per=" + per;

    axios
      .get(aiTrainingsURL, config)
      .then((response) => {
        if (response.status === 401 || response.status === 403) {
          navigate("/");
        }

        let responseData = JSON.parse(JSON.stringify(response.data));
        setAITrainings(responseData.ai_trainings);
      })
      .catch((err) => console.error("fetchAITrainings err = " + err.message));
  }, [apiBaseURL, id, navigate, per]);

  const updateAPIKey = (event) => {
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("struts_ai_access_token");

    setIsLoading(true);
    axios({
      url: `${apiBaseURL}/api_keys/${id}`,
      method: "PUT",
      withCredentials: false,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
        "x-strutsai-application": "user",
        "x-client-identifier": "web",
      },
      data: {
        name: keyName,
        ai_training_id: selectedAITrainingID,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data.api_key.id) {
          var successMessage = "API Key updated successfully.";
          setSuccessAlert(successMessage);
          setErrorAlert("");
        } else {
          setErrorAlert("Failed to update API Key.");
          setSuccessAlert("");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorAlert("Failed to update API Key. " + error);
        setSuccessAlert("");
      });
  };

  const handleDropdownChange = (event) => {
    console.log(event.target.value);
    setSelectedAITrainingID(event.target.value);
  };

  let alert;
  if (errorAlert.length > 0) {
    alert = (
      <div className="notification is-danger">&nbsp;&nbsp;{errorAlert} </div>
    );
  } else if (successAlert.length > 0) {
    alert = (
      <div className="notification is-success">&nbsp;&nbsp;{successAlert} </div>
    );
  }

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Generate API Key</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-half"
              style={{ marginBottom: "2px", marginTop: "-50px" }}
            >
              <nav
                className="breadcrumb has-arrow-separator"
                aria-label="breadcrumbs"
                style={{ fontSize: "9pt" }}
              >
                <ul>
                  <li style={{ color: "primary" }}>
                    <Link
                      to="/dashboard"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/api-keys"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      API Keys
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/api-keys/create"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Generate API Key
                    </Link>
                  </li>
                </ul>
              </nav>
              <p>
                <b>Generate API Key</b>
              </p>
              <br />
              {alert}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Name</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Name"
                        name="key_name"
                        value={keyName}
                        onChange={(e) => setKeyName(e.target.value)}
                        required
                      />
                    </div>

                    <br />
                    <label className="label">Select Training</label>
                    <div className="select">
                      <select
                        onChange={handleDropdownChange}
                        value={selectedAITrainingID}
                      >
                        {aiTrainings.map((aiTraining) => (
                          <option value={aiTraining.id} key={aiTraining.id}>
                            {aiTraining.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <br />

                  {!isLoading && (
                    <div className="field">
                      <button className="button is-link" onClick={updateAPIKey}>
                        Update
                      </button>
                    </div>
                  )}

                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditAPIKey;
