import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const AITraining = () => {
  let { id } = useParams();

  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [trainingData, setTrainingData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_ai_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    setIsLoading(true);

    var url = apiBaseURL + "/ai-trainings/" + id;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let aiTrainingData = JSON.parse(
          JSON.stringify(response.data.ai_training)
        );
        setTitle(aiTrainingData.title);
        setTrainingData(aiTrainingData.training_data);
        setIsLoading(false);
      })
      .catch((err) => console.error("fetch AI Training err = " + err.message));
  }, [apiBaseURL, id, navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">View AI Training</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-50px" }}
            >
              <nav
                class="breadcrumb has-arrow-separator"
                aria-label="breadcrumbs"
                style={{ fontSize: "9pt" }}
              >
                <ul>
                  <li>
                    <Link
                      to="/dashboard"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ai-trainings"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      AI Training
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/ai-trainings/" + id}
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      {title} AI Training View
                    </Link>
                  </li>
                </ul>
              </nav>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Title"
                        name="title"
                        value={title}
                        readonly
                      />
                    </div>
                  </div>

                  <div>
                    <textarea
                      class="textarea"
                      placeholder="AI Training Data"
                      name="training-data"
                      value={trainingData}
                      rows="20"
                      readOnly
                    ></textarea>
                  </div>

                  <br />

                  {!isLoading && (
                    <>
                      <Link
                        to={{
                          pathname: `/ai-trainings/edit/${id}`,
                        }}
                        className="button is-primary"
                      >
                        Edit
                      </Link>
                      &nbsp;&nbsp;
                      <Link
                        to={{
                          pathname: `/ai-trainings/delete/${id}`,
                        }}
                        className="button is-danger"
                      >
                        Delete
                      </Link>
                    </>
                  )}
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AITraining;
