import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";

const AnalyticsDocumentation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">Analytics</h2>
              <p className="">
                Once logged in, you will be able to see{" "}
                <Link to="/analytics" style={{ color: "#00d1b2" }}>
                  analytics
                </Link>{" "}
                similar to below. The values will change as you continue to use
                the system.
              </p>
              <br />
              <br />
              <img src="/assets/analytics.png" alt="Analytics" />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AnalyticsDocumentation;
