import React, { useEffect, useState } from "react";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import Modal from "../../../components/modal";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "bulma-pagination-react";

const APIKeys = () => {
  const navigate = useNavigate();
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const [recordID, setRecordID] = useState(0);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [api_keys, setApiKeys] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [per, setPer] = useState(8);
  const [pageCount, setPageCount] = useState(1);
  const [recordName, setRecordName] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState("");

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_ai_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);
    setPer(8);

    var url = apiBaseURL + "/api_keys?per=" + per;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        if (response.status === 401 || response.status === 403) {
          navigate("/");
        }

        let api_keysData = JSON.parse(JSON.stringify(response.data));
        let api_keysCount = api_keysData.pagination.count;
        setCount(api_keysCount);
        setApiKeys(api_keysData.api_keys);

        let numPages = Math.ceil(api_keysCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("fetchChats err = " + err.message));
  }, [apiBaseURL, per, navigate]);

  const fetchApiKeys = () => {
    let accessToken = localStorage.getItem("struts_ai_access_token");
    var url = apiBaseURL + "/api_keys?per=" + per;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let api_keysData = JSON.parse(JSON.stringify(response.data));
        let api_keysCount = api_keysData.pagination.count;
        setCount(api_keysCount);
        setApiKeys(api_keysData.api_keys);

        let numPages = Math.ceil(api_keysCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("fetchChats err = " + err.message));
  };

  const searchChats = (searchTermText) => {
    var accessToken = localStorage.getItem("struts_ai_access_token");
    var url = apiBaseURL + "/api_keys?search=" + searchTermText;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let api_keysData = JSON.parse(JSON.stringify(response.data));
        let api_keysCount = api_keysData.pagination.count;
        setCount(api_keysCount);
        setApiKeys(api_keysData.api_keys);

        let numPages = Math.ceil(api_keysCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("searchChats err = " + err.message));
  };

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage);
    filterApiKeys(selectedPage);
  };

  const filterApiKeys = (selectedPage) => {
    var accessToken = localStorage.getItem("struts_ai_access_token");

    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    var url = apiBaseURL + "/api_keys?page=" + selectedPage + "&per=" + per;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let api_keysData = JSON.parse(JSON.stringify(response.data));
        let api_keysCount = api_keysData.pagination.count;
        setCount(api_keysCount);
        setApiKeys(api_keysData.api_keys);

        let numPages = Math.ceil(api_keysCount / per);
        setPageCount(numPages);
      })
      .catch((err) => console.error("filter API Keys err = " + err.message));
  };

  const toggleModal = (props, id) => {
    let selectedAPIKey = api_keys.filter((item) => {
      return item.id === id;
    });

    setRecordName(selectedAPIKey[0].name + " API Key");
    setRecordID(id);
    setModalState(!props.modalState);
  };

  const closeModal = () => {
    setModalState(false);
  };

  const deleteApiKey = () => {
    let accessToken = localStorage.getItem("struts_ai_access_token");
    setModalState(false);
    var url = apiBaseURL + "/api_keys/" + recordID;

    const config = {
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .delete(url, config)
      .then((response) => {
        if (response.status === 200) {
          fetchApiKeys();
          setSuccessAlert("API Key Deleted Successfully.");
          setErrorAlert("");
        }
      })
      .catch((err) => console.error("API Key deletion err = " + err.message));
  };

  let alert;
  if (errorAlert.length > 0) {
    alert = (
      <div className="notification is-danger">&nbsp;&nbsp;{errorAlert} </div>
    );
  } else if (successAlert.length > 0) {
    alert = (
      <div className="notification is-success">&nbsp;&nbsp;{successAlert} </div>
    );
  }

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title" style={{ color: "#404040" }}>
            API Keys
          </h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "2px", marginTop: "-50px" }}
            >
              <nav
                className="breadcrumb has-arrow-separator"
                aria-label="breadcrumbs"
                style={{ fontSize: "9pt" }}
              >
                <ul>
                  <li>
                    <Link
                      to="/dashboard"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/api-keys"
                      style={{
                        color: "#00d1b2",
                        fontWeight: "bold",
                        fontSize: "9pt",
                      }}
                    >
                      API Keys
                    </Link>
                  </li>
                </ul>
              </nav>

              <div className="columns">
                <div className="column">
                  <b>API Keys ({count})</b>
                  &nbsp;&nbsp;
                  <Link
                    to="/api-keys-documentation"
                    style={{
                      color: "#00d1b2",
                      fontWeight: "bold",
                      fontSize: "9pt",
                    }}
                  >
                    Info
                  </Link>
                </div>
                <div className="column"></div>
                <div className="column"></div>
                <div className="column">
                  <div className="bd-notification2 is-primary is-pulled-right">
                    <Link to="/api-keys/create">
                      <button className="button is-link">
                        Generate an API Key
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <p>
                <input
                  className="input"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => searchChats(e.target.value)}
                />
              </p>
              <br />
              {alert}
              <table
                className="table is-striped is-hoverable"
                style={{ width: "100%" }}
              >
                <thead style={{ backgroundColor: "#00d1b2" }}>
                  <tr>
                    <th>Name</th>
                    <th>Key</th>
                    <th>Last Used</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {api_keys.map((api_key) => (
                    <tr key={api_key.id}>
                      <th>{api_key.name}</th>
                      <td>{api_key.key.substring(0, 5)}...</td>
                      <td>{api_key.last_used_at}</td>
                      <td>{api_key.created_at.substring(0, 10)}</td>
                      <th>
                        <Link to={{ pathname: `/api-keys/${api_key.id}` }}>
                          <span
                            className="icon has-text-primary"
                            data-tooltip="View"
                          >
                            <i
                              className="fas fa-eye is-primary"
                              data-tooltip="View"
                            ></i>
                          </span>
                        </Link>
                        &nbsp;
                        <Link
                          to={{
                            pathname: `/api-keys/edit/${api_key.id}`,
                          }}
                        >
                          <span class="icon">
                            <i class="fas fa-edit"></i>
                          </span>
                        </Link>
                        &nbsp;
                        <span
                          className="icon has-text-danger"
                          onClick={(event) => toggleModal(event, api_key.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fas fa-trash"></i>
                        </span>
                        <Modal
                          id={api_key.id}
                          closeModal={closeModal}
                          modalState={modalState}
                          deleteFunction={deleteApiKey}
                          recordName={recordName}
                          recordID={recordID}
                        />
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div
                className="container pagination is-centered"
                style={{
                  margin: "0 auto",
                  width: "max-content",
                }}
              >
                <Pagination
                  pages={pageCount}
                  currentPage={currentPage}
                  onChange={(page) => handlePageClick(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default APIKeys;
