import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const XeroConfiguration = () => {
  const navigate = useNavigate();
  let apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  let [isConnected, setIsConnected] = useState(false);
  let [modalState, setModalState] = useState(false);
  let [tokenID, setTokenID] = useState(0);
  let [xeroConfiguration, setXeroConfiguration] = useState({});

  useEffect(() => {
    let accessToken = localStorage.getItem("struts_ai_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    let url = apiBaseURL + "/xero-config";

    const config = {
      headers: {
        "x-strutsai-token": accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let xeroConfig = JSON.parse(JSON.stringify(response.data));
        setXeroConfiguration(xeroConfig);
        if (xeroConfig.refresh_token && xeroConfig.refresh_token.length > 0) {
          setIsConnected(true);
        }
        setTokenID(xeroConfig.id);
      })
      .catch((err) => console.error("xeroConfig err = " + err.message));
  }, [apiBaseURL, navigate, modalState]);

  const Modal = ({ children, closeModal, modalState, title }) => {
    if (!modalState) {
      return null;
    }

    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={closeModal} />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{title}</p>
            <button className="delete" onClick={closeModal} />
          </header>
          <section className="modal-card-body">
            <div className="content">{children}</div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-danger" onClick={deleteXeroConnection}>
              Delete
            </button>
            <button className="button" onClick={closeModal}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    );
  };

  const toggleModal = (props) => {
    setModalState(!props.modalState);
  };

  const closeModal = () => {
    setModalState(false);
  };

  const deleteXeroConnection = () => {
    let accessToken = localStorage.getItem("struts_ai_access_token");
    setModalState(false);
    var url = apiBaseURL + "/xero-config/" + tokenID;

    const config = {
      headers: {
        "x-strutsai-token": accessToken,
        "x-strutsai-application": "user",
      },
    };

    axios
      .delete(url, config)
      .then((response) => {
        let res = JSON.parse(JSON.stringify(response.data));

        if (res.status && res.status === "Ok") {
          setXeroConfiguration({});
          setIsConnected(false);
        }
      })
      .catch((err) =>
        console.error("xeroConfig deletion err = " + err.message)
      );
  };

  return (
    <>
      <p>
        <b>Xero Configuration</b>
      </p>

      <br />

      <table className="table is-three-quarters" width="100%">
        <thead>
          <tr>
            <th>Organization</th>
            <th>Tenant Type</th>
            <th>Status</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{xeroConfiguration.organization_name}</td>
            <td>{xeroConfiguration.tenant_type}</td>
            <td>
              {isConnected && (
                <strong className="has-text-primary">Connected</strong>
              )}
            </td>
            <td>
              {isConnected && (
                <div>
                  <button className="button is-danger" onClick={toggleModal}>
                    Delete
                  </button>

                  <Modal
                    closeModal={closeModal}
                    modalState={modalState}
                    title="Confirm Xero Connection Deletion"
                  >
                    <p>Are you sure you want to delete the Xero Connection?</p>
                  </Modal>
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      {!isConnected && (
        <div className="buttons">
          <a className="button is-info" href={apiBaseURL + "/xero-auth"}>
            <strong>Connect Xero</strong>
          </a>
        </div>
      )}
    </>
  );
};
export default XeroConfiguration;
